<template>
  <v-container class="linerp-layout py-0">
    <v-row class="align-start">
      <v-col class="px-0">
        貨號設定
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn color="primary" small @click="handleDialogSetSkuSwitch(true)">規格貨號</v-btn>
      </v-col>
    </v-row>
    <DialogSetSku
      :showDialogSetSku="showDialogSetSku"
      :handleDialogSetSkuSwitch="handleDialogSetSkuSwitch"
      :handleDialogSetSkuFinish="handleDialogSetSkuFinish"
      :specs="formData.specs"
      :sku="formData.sku"
      :generate_spec_price="formData.generate_spec_price"
      :productName="productName"
    />
  </v-container>
</template>

<script>
import DialogSetSku from "./DialogSetSku.vue";
import formItemMixin from '@/components/form/mixins/formItemMixin'

export default {
  components: {
    DialogSetSku,
  },
  mixins: [formItemMixin],
  data() {
    return {
      showDialogSetSku: false,
    };
  },
  computed: {
    specs() {
      return this.formData.specs
    },
    productName() {
      return this.formData.name
    },
  },
  methods: {
    handleDialogSetSkuSwitch(status) {
      this.showDialogSetSku = status
    },
    handleDialogSetSkuFinish() {
      this.showDialogSetSku = false
    },
  },
};
</script>

<style scoped>
h1 {
  color: #42b983;
}
</style>
