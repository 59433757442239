<template>
  <v-dialog
    v-model="showDialogSetSku" fullscreen hide-overlay :contained="true" transition="dialog-bottom-transition"
  >
    <v-card class="linerp-layout">
      <div class="d-flex justify-space-between align-center px-6 pt-4">
        <page-title title="貨號設定"/>
        <v-btn @click="handleDialogSetSkuSwitch(false)" text icon>
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </div>
      <v-container class="my-2" v-if="!isMultiSpec">
        <v-row class="px-3 pb-2">
          <v-col class="py-0 px-0 d-flex justify-start align-center font-weight-bold">
            <div>{{ productName || '(無商品名稱)' }}</div>
          </v-col>
        </v-row>
        <v-row class="px-3 pb-2">
          <v-col class="py-0 px-0 d-flex justify-center align-center">
            <v-text-field
              dense
              outlined
              maxlength="10"
              counter
              placeholder="請輸入產品貨號"
              hide-details
              class=""
              v-model="sku"
              rounded
              style="font-size: 14px;"
            ></v-text-field>
            </v-col>
        </v-row>
      </v-container>
      <template v-for="(priceV, priceI) in generate_spec_price" v-else>
        <v-container v-if="priceV.groupItems.length > 0" :key="priceI" class="my-2">
          <v-row class="px-3 pb-2" v-if="priceV.groupName">
            <v-col class="py-0 px-0 d-flex justify-start align-center font-weight-bold">
              <div>{{ priceV.groupName }}</div>
            </v-col>
          </v-row>
          <v-row v-for="(itemV, itemI) in priceV.groupItems" :key="itemI" class="px-3 pb-2">
            <v-col cols="2" class="py-0 px-0 d-flex justify-start align-center" style="word-break: break-all;">{{ itemV.spec2 || itemV.spec1 }}</v-col>
            <v-col class="py-0 px-0 d-flex justify-center align-center">
              <v-text-field
                  dense
                  outlined
                  maxlength="10"
                  counter
                  placeholder="請輸入規格貨號"
                  hide-details
                  class=""
                  v-model="itemV.sku"
                  rounded
                  style="font-size: 14px;"
                ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <v-container>
        <v-btn color="primary" small width="100%" @click="handleDialogSetSkuFinish">
          設定完成
        </v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showDialogSetSku: {
      type: Boolean,
      required: true,
    },
    handleDialogSetSkuSwitch: {
      type: Function,
      required: true,
    },
    handleDialogSetSkuFinish: {
      type: Function,
      required: true,
    },
    specs: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    generate_spec_price: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    sku: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    },
    productName: {
      type: String,
      required: false,
      default: () => {
        return []
      }
    }
  },
  computed: {
    isMultiSpec(){
      return this.generate_spec_price.length > 0 && this.generate_spec_price.some(v => v.groupName && v.groupItems.length > 0)
    }
  },
  data() {
    return {
      loading: false,
      skuString: ''
    };
  },
  methods: {},
};
</script>
